<template>
    <component :is="newsData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="newsData === undefined">
            <h4 class="alert-heading">Error fetching news data</h4>
            <div class="alert-body">
                No news found with this news id. Check
                <b-link class="alert-link" :to="{ name: 'news-list' }">
                    News List
                </b-link>
                for other news.
            </div>
        </b-alert>
        <news-edit-tab-information v-if="newsData"  :news-data="newsData" :language-data="languageData" @refetch-data="refetchData" class="mt-2 pt-75" />
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import newsStoreModule from "../newsStoreModule";
// import NewsEditTabAccount from "./NewsEditTabAccount.vue";
import NewsEditTabInformation from "./NewsEditTabInformation.vue";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        // NewsEditTabAccount,
        NewsEditTabInformation,
    },
    setup() {
        const newsData = ref({});
        
        const NEWS_STORE_MODULE_NAME = "news";

        const news = {
            id: 0,
            is_active: 1,
            //order_number: 0,
            seo_link: '',
            meta_keyword: '',
            meta_description: '',
            published_date: '',
            contents: [{
                language_id: 1,
                title: '',
                content: '',
            },
            {
                language_id: 2,
                title: '',
                content: '',
            },
           ]
        }

        const contentData = ref([])
        const languageData = ref(
            [{id:1, name:'EN'}, {id:2, name:'䌓體中文'}, {}]
            )
        

        // Register module
        if (!store.hasModule(NEWS_STORE_MODULE_NAME))
            store.registerModule(NEWS_STORE_MODULE_NAME, newsStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(NEWS_STORE_MODULE_NAME))
                store.unregisterModule(NEWS_STORE_MODULE_NAME);
        });

        const refetchData = () => {

            store.dispatch("news/fetchLanguages")
            .then((response) => {
                languageData.value = response.data.languages
                console.log('languageData',languageData.value)
            }).catch((error) => {
                console.log('Error in fetching languages error', error)
            })

            if (router.currentRoute.name == 'news-create') {
                newsData.value = news
            } else {
                store
                    .dispatch("news/fetchNews", { id: router.currentRoute.params.id })
                    .then((response) => {
                        newsData.value = response.data.news;
                        console.log('newsData', newsData.value)
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            newsData.value = undefined;
                        }
                    });
            }
        };
        refetchData()
        return {
            newsData,
            languageData,
            refetchData,
            
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
