<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">News Information</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!--Field: News code -->
            <b-col cols="12" v-if="newsData.image_path">
              <hr />
              <img style="width: 100%" :src="newsData.image_path" />
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>
            <b-col md="12">
              <b-form-group label="News Image" label-cols-md="3">
                <el-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  ref="upload"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="image_file"
                  :file-list="fileList"
                  multiple
                  :limit="1"
                  :data="newsData"
                  :on-change="onChange"
                  :on-remove="toggleUpload"
                  :before-remove="beforeRemove"
                  :auto-upload="false"
                  :class="{ hideUpload: !showUpload }"
                >
                  <img
                    v-if="newsData.image_path && false"
                    :src="newsData.image_path"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>

            <!-- Directors Loop -->
            <b-row
              v-for="(item, index) in newsData.contents"
              :id="item.id"
              :key="index"
              ref="row"
              style="margin: 1px; width: 100%"
            >
              <!-- News Title -->

              <div class="d-flex">
                <h6 class="mb-0 ml-1">
                  {{ getLanguage(item.language_id) }}
                </h6>
              </div>

              <b-col md="12">
                <b-form-group label="Title" label-cols-md="3">
                  <b-form-input
                    type="text"
                    placeholder="Title"
                    v-model="item.title"
                  />
                </b-form-group>
              </b-col>

              <!-- News Content-->
              <b-col md="12">
                <b-form-group label="Content" label-cols-md="3">
                  <quill-editor v-model="item.content" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- Remove Button
                <b-col md="2" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Delete</span>
                    </b-button>
                </b-col>
                    -->
            </b-row>

            <!-- Field: Order
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Order"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Order"
                  label-for="order_number"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="order_number"
                    :state="getValidationState(validationContext)"
                    v-model="newsData.order_number"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            -->
            <!-- Field: Slug-->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Slug"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Slug"
                  label-for="seo_link"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="seo_link"
                    :state="getValidationState(validationContext)"
                    v-model="newsData.seo_link"
                    @update="checkSlug"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Meta Title"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Meta Title"
                  label-for="meta_title"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="meta_title"
                    :state="getValidationState(validationContext)"
                    v-model="newsData.meta_title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Meta Keyword"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Meta Keyword"
                  label-for="meta_keyword"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="meta_keyword"
                    :state="getValidationState(validationContext)"
                    v-model="newsData.meta_keyword"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Meta Description"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Meta Description"
                  label-for="meta_description"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="meta_description"
                    :state="getValidationState(validationContext)"
                    v-model="newsData.meta_description"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Published Date-->
            <b-col cols="12" md="12" v-if="false">
              <validation-provider
                #default="validationContext"
                name="Published Date"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Published Date"
                  label-for="published_date"
                  label-cols-md="3"
                >
                  <b-form-datepicker
                    id="published_date"
                    v-model="newsData.published_date"
                    :state="getValidationState(validationContext)"
                    class="mb-2"
                  >
                  </b-form-datepicker>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Status -->
            <b-col cols="12" md="12">
              <b-form-group
                label="Status"
                label-for="is_active"
                label-cols-md="3"
              >
                <!-- <b-form-input id="is_active"  v-model="newsData.is_active" /> -->
                <b-form-checkbox
                  :checked="newsData.is_active"
                  name="is_active"
                  switch
                  inline
                  v-model="newsData.is_active"
                  value="1"
                >
                  {{ newsData.is_active ? "Active" : "InActive" }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- -->
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>

          <!-- Form Actions -->

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ button_text }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {

  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (
        file.url == this.newsData.image_path
      ) {
        this.removeExistingPic = true;
        this.newsData.image_path = null;
      }
    },
    checkSlug() {
      this.newsData.seo_link = this.newsData.seo_link
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    getLanguage(id) {
      if (this.languageData.length > 0) {
        var language = this.languageData.find((item) => item.id == id);
        return language.name;
      }
    },
    /*
    getImgPath(path){
        return process.env.VUE_APP_IMAGE_URL+path;
    },
    */
    removeItem(index) {
      this.newsData.directors.splice(index, 1);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    repeateAgain() {
      this.newsData.directors.push({
        id: this.newsData.directors.length,
      });
    },
    hide() {
      this.$router.replace("/news/list");
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          formData.append("file", this.newsData.file);
          formData.append("id", this.newsData.id);
          formData.append("removeExistingPic", this.removeExistingPic);
          //formData.append('contents', this.newsData.contents)
          for (let i = 0; i < this.newsData.contents.length; i++) {
            for (let key of Object.keys(this.newsData.contents[i])) {
              //console.log('hello...', this.newsData.contents[i][key]);
              formData.append(
                `contents[${i}][${key}]`,
                this.newsData.contents[i][key]
              );
            }
          }
          formData.append("news_category_id", this.newsData.news_category_id);
          formData.append("is_active", this.newsData.is_active ? 1 : 0);
          // formData.append('order_number', this.newsData.order_number)
          formData.append("seo_link", this.newsData.seo_link);
          formData.append("meta_title", this.newsData.meta_title);
          formData.append("meta_keyword", this.newsData.meta_keyword);
          formData.append("meta_description", this.newsData.meta_description);
          // formData.append('published_date', this.newsData.published_date)

          this.loading = true;
          store
            .dispatch("news/addNews", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.removeExistingPic = false;
              this.$emit("refetch-data");
              if (this.newsData.id == 0) {
                // Return to news list
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    newsData: {
      type: Object,
      required: true,
    },
    languageData: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const answer = ref([]);
    const button_text = ref("Add");
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/news");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const fileList = ref([]);
    const removeExistingPic = ref(false);
    const showUpload = ref(true);

    const resetnewsData = () => {
      props.newsData = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetnewsData);

    const checkEdit = () => {
      if (props.newsData.id > 0) {
        button_text.value = "Edit";
      } else {
        button_text.value = "Add";
      }
    };

    checkEdit();

    watch(
      () => props.newsData,
      (selection, prevSelection) => {
        checkEdit();
        if (props.newsData.image_path) {
          fileList.value = [
            { url: props.newsData.image_path },
          ];
          showUpload.value = false;
        }
      }
    );

    const onChange = (file, fileLists) => {
      showUpload.value = !showUpload.value;
      props.newsData.file = file.raw;
    };

    return {
      answer,
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      uploadUrl,
      headerObj,
      fileList,
      onChange,
      removeExistingPic,
      showUpload,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.required > label::after {
  content: " **";
  color: red;
}

.vs__selected path {
  fill: white;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;
    .dark-layout & {
      background-color: unset;
    }
  }
}
.add-new-group-header {
  color: #28c76f;
  margin-left: 15px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}
.hideUpload > div {
  display: none;
}
</style>
